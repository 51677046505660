import React, { useState, useContext, useEffect } from "react";

import Layout from '../../components/layout';
import ProductTable from '../../components/product/productTable'
import ProductFilter from '../../components/product/productFilter'

import { MenuContext } from '../../context'

import ProductsData from '../../data/products.json'

import ChevronUp from '../../assets/chevron-up.inline.svg';
import ChevronDown from '../../assets/chevron-down.inline.svg';

import ContactUs from '../../components/contact-us/contact-us.component';
import Resources from '../../components/resources/resources.component';

import { LATEST_RATE_SHEET } from '../../data/latest-rate-sheet.data';
import '../../styles/app.scss';

const renderRowSubComponent = ({ row }) => {
  return (
    <div dangerouslySetInnerHTML={{
      __html: `Product description | <span>${row.original.ProductDescription1}</span>`
    }}></div>
  );
};

const ProductFinder = () => {
  const [productData, setProductData] = useState([])
  const [isFilterEmpty, setIsFilterEmpty] = useState(true)

  const menuContext = useContext(MenuContext);

  const handleClick = (event) => {
    if (event.target.hasAttribute('data-tealium') || event.target.parentNode.hasAttribute('data-tealium')) {
      if (typeof window !== "undefined") {
        if(window.utag){
          window.utag.link({page_subcategory: "products"})
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

 
  if (typeof window !== "undefined") {
    if(window.utag){
      window.utag.view({page_subcategory: "products"})
    }
  }

  const columns = React.useMemo(
    () => [
      { Header: "Product name", accessor: "ProductTerm" },
      { Header: "Initial rate", accessor: "Rate", collapse: true },
      { Header: "Product fee", accessor: "ProductFee", collapse: true },
      { Header: "Variable rate", accessor: "Reversionary Rate %", collapse: true },
      { Header: "Overall APR", accessor: "APR", collapse: true },
      { Header: "Max LTV", accessor: "MaxLTV", collapse: true },
      { Header: "Cashback", accessor: "Cashback", collapse: false },
      { Header: "Cashback value", accessor: "CashbackValue", collapse: false },
      { Header: "Tracked / fixed until", accessor: "Deal Period (Fixed until)", collapse: false },
      { Header: "Overpayment allowance", accessor: "Overpayments" },
      { Header: "Product code", accessor: "NewProductCode", collapse: true },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span
            {...row.getToggleRowExpandedProps()}
            role="button"
            tabIndex="0"
            aria-label="Toggle More Information"
            // because isExpanded doesn't provide false when not expanded
            aria-expanded={row.isExpanded || false}
            inert={menuContext.isMenuOpen ? "true" : null}

          >
            {row.isExpanded ? <ChevronUp /> : <ChevronDown />}
          </span>
        ),
        collapse: true,
      }
    ],
    [menuContext]
  );

  return (
    <Layout title='Product finder'>
      <section className='product-finder-page page-section row'>
        <div className='col'>
          <div className="row">
            <div className='col-xs-12 col-md-12 col-lg-12 col-xl-7'>
              <h1 className='page-title'>Product finder</h1>
              <p className='mb-20'>
                If you want to browse all the mortgage products that match your
                customer's criteria, search 'by feature'. If you'd like to find out
                more about a product you're interested in, search 'by product code'.
              </p>
              <p className='mb-20'>
                Naturally, the figures and information provided are for illustration
                only and do not constitute an offer to lend.
              </p>
              <ProductFilter
                data={ProductsData}
                setData={setProductData}
                setIsFilterEmpty={setIsFilterEmpty}
              />
            </div>
            <div className='right-side col-xs-12 col-md-12 col-lg-12 col-xl-4 col-lg-offset-1'>
              <ContactUs />
              <Resources
                title="Mortgage rates PDF"
                linkItems={[]}
                downloadableItems={[{
                  title: LATEST_RATE_SHEET.title,
                  isDownload: true,
                  isBlank: true,
                  href: LATEST_RATE_SHEET.href,
                }]}
                isProductFinder={true}
              />
            </div>
          </div>
        </div>
      </section>
      <section
        className='product-finder-page'
        style={{ width: "inherit", overflowX: "auto" }}
      >
        <div className="product-table responsive-table">
          <p role="status" aria-atomic="true" className="result-count">
            {!isFilterEmpty ? (
              <><span>{productData.length}</span> Products match your criteria</>
            ) : null}
          </p>
          {(productData.length > 0) && (
            <ProductTable
              columns={columns}
              data={productData}
              renderRowSubComponent={renderRowSubComponent}
            />
          )}
        </div>
      </section>
      <div className='row'>
        <div className='col-xs-12 col-md-12 col-lg-12 col-xl-7'>
          <div className="informations">
            <p className='information usage first'>
              This website is for the use of FCA authorised mortgage intermediaries
              only.
            </p>
            <p className='information usage'>
              If you reproduce any information contained in this website, to be used
              with or advise clients, you must ensure it follows the FCA's advising
              and selling standards.
            </p>
          </div>
        </div>
      </div>
      <section className='product-finder-page row'>
        <div className='footer-content-side support-container col-xs-12'>
          <ContactUs />
          <Resources
            title="Mortgage rates PDF"
            linkItems={[]}
            downloadableItems={[{
              title: LATEST_RATE_SHEET.title,
              isDownload: true,
              isBlank: true,
              href: LATEST_RATE_SHEET.href,
            }]}
          />
        </div>
      </section>
    </Layout>
  );
}

export default ProductFinder;
